import { useCookies } from 'react-cookie';

const isElavon: boolean = process.env.CONTENT_BRAND === 'elavon'

const displayVersionListItems = (data: any, id: string, name: string): any => {
    const [cookies] = useCookies(['locale']);
    const locale = cookies.locale || 'en-us';
    const lang = locale.substring(3);
  
    if (!isElavon && data && Object.keys(data).length && data[process.env.CONTENT_BRAND]?.products[id]) {
      let displayVersionList: any = Object.values(data[process.env.CONTENT_BRAND]?.products[id]?.displayVersionList[lang]) || [];
      let displayVersionListItems: any = [];
  
      displayVersionListItems = displayVersionList.map((item: any, index: number) => {
        const version = Object.keys(item)[0];
        return {
          id: index + 1,
          value: name ? `${name} ${version}` : `Version: ${version}`,
          isSelectable: true,
        };
      });
  
      return displayVersionListItems;
    }

    return false 
  }

const getProductVisibilityLevel = (prodVersionData: any, prodId: string, lang: string, version: string, sessionData: any) => {
    
    const filteredVersionList = prodVersionData[process.env.CONTENT_BRAND]?.products[prodId]?.displayVersionList[lang]?.filter((item: any) => version in item) || [];
    const visibilityLevel = filteredVersionList[0] && filteredVersionList[0][version]?.visibilityLevel || 'public';
    const restrictedPrivateProducts = sessionData?.restrictedPrivateProducts || []
    let isProductRestrictedPrivate: any = false;
    restrictedPrivateProducts?.length > 0 && restrictedPrivateProducts.forEach((value: any) => {
      if(value[prodId] && value[prodId][lang]) {
        isProductRestrictedPrivate = value[prodId][lang].filter((item: any) => item === version)
      }
    })
    return [visibilityLevel, isProductRestrictedPrivate];
}
  
export { displayVersionListItems, getProductVisibilityLevel }
