import '@usb-shield/react-link/dist/library/styles/index.css'
import '@usb-shield/react-grid/dist/library/styles/index.css'

import * as Tealium from '@/modules/tealium/Tealium'

import { RedocStandalone } from '@usb-shared-openbanking/edp-redoc'
import { buildNestedStructure } from '@/utils/build-nested-list/build-nested-elements.util'
import { findItemIdByPath } from '@/utils/find-item-id-by-path'
import styles from '@/modules/products/ProductDocumentPage/DisplayViews/displayViews.module.scss'
import { useCookies } from 'react-cookie'
import { useRouter } from 'next/router'

const ThreeColRedocLayout = ({
  pageData,
  specData,
  sideNavData,
  productName,
}: {
  pageData: any
  specData: any
  sideNavData: any
  productName: any
}) => {
  const builderNestedStructureSideNav = buildNestedStructure(sideNavData) as any
  let additionalMenuItems = builderNestedStructureSideNav

  const [cookies] = useCookies(['locale'])
  const router = useRouter()

  const itemId: string | undefined = findItemIdByPath(
    builderNestedStructureSideNav,
    router.asPath
  )

  let headerName = 'API Reference'
  let productLink = '/products'
  if (router) {
    headerName = router.asPath.split('/')[4].includes('webhook')
      ? 'Webhook'
      : 'API Reference'

    productLink =
      '/products' +
      '/' +
      router.asPath.split('/')[2] +
      '/' +
      router.asPath.split('/')[3]
  }

  let breadcrumbItems = [
    { id: 1, text: 'Home', href: '/' },
    { id: 2, text: 'API products', href: '/products' },
    {
      id: 3,
      text: pageData?.metaData?.name,
      href: productLink,
    },
    {
      id: 4,
      text: headerName,
      href: '',
    },
  ] as any

  if (process.env.DISABLE_ALL_PRODUCTS_PAGE?.toLowerCase() === 'true') {
    breadcrumbItems.splice(1, 1)
  }

  if (process.env.CONTENT_BRAND === 'elavon') {
    additionalMenuItems = undefined
    breadcrumbItems = [
      { id: 1, text: 'Home', href: '/' },
      {
        id: 2,
        text: pageData?.metaData?.name,
        href: productLink,
      },
      {
        id: 3,
        text: headerName,
        href: '',
      },
    ] as any
  }

  return (
    <div className={styles.contentWrapper}>
      <div
        className={
          process.env.SITE_BRAND === 'elavon'
            ? `${styles.redocDiv} ${styles.topPadding}`
            : `${styles.redocDiv}`
        }
      >
        <RedocStandalone
          onLoaded={Tealium.attachEventHandlers}
          activeId={itemId}
          specUrl={specData}
          title={headerName}
          productName={productName}
          additionalMenuItems={additionalMenuItems}
          breadcrumbItems={breadcrumbItems}
          contentBrand={process.env.CONTENT_BRAND}
          contentDomain={process.env.CONTENT_DOMAIN}
          contentBasePath={process.env.CONTENT_BASE_PATH}
          cookiesLocale={cookies.locale}
          options={{
			      scrollYOffset: 100,
            theme: {
              breakpoints: {
                small: '1056px',
              },
              colors: {
                primary: {
                  main: '#0A41C5',
                  light: 'rgba(246, 20, 63, 0.42)',
                },
              },
              typography: {
                fontSize: '16px',
                fontFamily:
                  'Circular-USB, sans-serif',
                optimizeSpeed: true,
                smoothing: 'antialiased',
                headings: {
                  fontWeight: 'bold',
                  lineHeight: '1em',
                },
                code: {
                  fontWeight: '600',
                  wrap: true,
                },
                links: {
                  color: '#346DF4',
                  visited: '#0A41C5',
                },
              },
              sidebar: {
                width: '200px',
                textColor: '#000000',
                backgroundColor: '#ffffff',
                activeTextColor: '#0A41C5',
              },
            },
          }}
        />
      </div>
    </div>
  )
}

export default ThreeColRedocLayout
