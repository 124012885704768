import React from 'react'
import '@usb-shield/react-modal/dist/library/styles/index.css'
import { useRouter } from 'next/router'
import { useSession } from 'next-auth/react'
import PromoteCTA from '@/modules/credentials/projects/components/PromoteCTA/PromoteCTA'
import USBLink from '@usb-shield/react-link'
import { downloadDocument } from '../services/downloadDocument.service'
import { useCookies } from 'react-cookie'
import { ApiProductDetails } from '@/modules/products/ProductOverviewPage/productOverviewPage.model'

let router: any,
  title: any,
  value: any,
  cookies: any,
  documentDetails: any,
  promoteSuccessHandler: any

function authenticatedProduct(sessionInfo: any) {
  if (userLoggedOut(sessionInfo)) {
    return loggedOutUser()
  }

  if (sessionInfo && value?.metaData?.fileType == 'download') {
    return downloadLink(value.path, value.title)
  }

  return rightRailLink()
}

function downloadLink(path: any, title: any) {
  return (
    <USBLink
      linkType="arrow"
      addClasses="download-link"
      handleClick={async () => {
        const documentPathWithoutSlash = path.replace('/', '')
        const documentPath = documentPathWithoutSlash
        await downloadDocument(documentPath, title, cookies[0].locale)
      }}
    >
      {title}
    </USBLink>
  )
}

function loggedOutUser() {
  let linkUrl = value.path

  return (
    <USBLink
      linkType="arrow"
      addClasses="login-redirect-link"
      handleClick={() => {
        router.push({
          pathname: '/login',
          query: {
            callbackUrl:
              value?.metaData?.fileType == 'download'
                ? router.asPath
                : linkUrl,
          },
        })
      }}
    >
      {title}
    </USBLink>
  )
}

function noOrganizationRestricted() {
  return (
    <PromoteCTA
      ctaText={title}
      documentDetails={documentDetails}
      type={'link'}
      promoteSuccessHandler={promoteSuccessHandler}
    />
  )
}

function restrictedPrivateProduct(sessionInfo: any) {
  if (userLoggedOut(sessionInfo)) {
    return loggedOutUser()
  }

  if (sessionInfo && value?.metaData?.fileType == 'download') {
    return downloadLink(value.path, value.title)
  }

  return rightRailLink()
}

function restrictedProduct(sessionInfo: any) {
  if (userLoggedOut(sessionInfo)) {
    return loggedOutUser()
  }

  if (!sessionInfo?.decodedAccessToken?.organization) {
    return noOrganizationRestricted()
  }

  if (sessionInfo && value?.metaData?.fileType == 'download') {
    return downloadLink(value.path, value.title)
  }

  return rightRailLink()
}

function rightRailLink() {
  return (
    <USBLink addClasses="right-rail-link" linkType="arrow" href={value?.path}>
      {title}
    </USBLink>
  )
}

function userLoggedOut(sessionInfo: any) {
  if (!sessionInfo) {
    return true
  }

  return false
}

const ProductOverviewRightRailLinks = ({
  apiProductDetails,
  productValue,
  promoteSuccessHandlerCb,
  visibilityLevel,
}: {
  apiProductDetails: ApiProductDetails
  productValue: any
  promoteSuccessHandlerCb: () => void
  visibilityLevel: string
}) => {
  const { data: session, status } = useSession(),
    getDocumentationLinks = (value: any, sessionInfo: any) => {
      documentDetails = {
        productName: apiProductDetails.productName,
        documentLink: `${window.location.origin.toString()}` + value?.path,
      }

      let retVal: any = <></>

      switch (visibilityLevel) {
        case 'authenticated':
          retVal = authenticatedProduct(sessionInfo)

          break

        case 'restricted-private':
          retVal = restrictedPrivateProduct(sessionInfo)

          break

        case 'restricted':
          retVal = restrictedProduct(sessionInfo)

          break
        
        case 'public':
          retVal = rightRailLink()

          break
      }

      return retVal
    }

  let rightRailDocumentationLinks = <></>

  value = productValue
  cookies = useCookies(['locale'])
  promoteSuccessHandler = promoteSuccessHandlerCb
  title = productValue?.title
  router = useRouter()

  if (status !== 'loading') {
    rightRailDocumentationLinks = getDocumentationLinks(value, session)
  }

  return <div>{rightRailDocumentationLinks}</div>
}

export default ProductOverviewRightRailLinks
