import axios from 'axios'
import { AxiosResponse } from 'axios'

export async function sandboxDropdownChangeCb(e: any) {
  const basePath =
      process.env.CONTENT_DOMAIN +
      '/' +
      process.env.CONTENT_BASE_PATH +
      'en-us' +
      '/' +
      process.env.CONTENT_BRAND,
    PATH_ARR = window.location.pathname.split('/'),
    VERSION = e.target.outerText.split('Version:')[1].trim(),
    REQUEST_PATH = `${basePath}/${PATH_ARR[1]}/${PATH_ARR[2]}/${VERSION}.sidenavdata.json`,
    headers = {
      'content-type': 'application/json',
    }

  let loc: string,
    page: string = PATH_ARR[4],
    res: AxiosResponse,
    versionLinks: any

  e.preventDefault()

  res = await axios.get(REQUEST_PATH, {
    headers,
    proxy: false,
  })

  versionLinks = res.data.map((ele: any) => {
    return ele?.path
  })

  if (res?.data[0]?.path) {
    page = res.data[0].path.split('/').pop() || ''
  }

  versionLinks.forEach((element: string) => {
    if (element.includes(PATH_ARR[4])) {
      page = PATH_ARR[4]
    }
  })

  loc = '/' + PATH_ARR[1] + '/' + PATH_ARR[2] + '/' + VERSION + '/' + page

  window.location.href = loc

  return loc
}
