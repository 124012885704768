import '@usb-shield/react-link/dist/library/styles/index.css'
import '@usb-shield/react-link/dist/library/styles/index.css'
import '@usb-shield/react-grid/dist/library/styles/index.css'
import 'highlight.js/styles/default.css'
import styles from "@/modules/products/ProductDocumentPage/DisplayViews/displayViews.module.scss";
import dynamic from "next/dynamic";
import BlockArrayWrapper from '@/components/BlockArrayWrapper/BlockArrayWrapper'
import GenericSection from '@/components/GenericSection/GenericSection';
// import BlockArrayWrapperData from '../../../../../../common/components/BlockArrayWrapper/blockArrayWrapper.model'
// import { useState, useEffect } from 'react'

const ReactMarkDown = dynamic(
    () => import("@/components/ReactMarkDown/ReactMarkDown"),
    {
      ssr: false,
    }
);

const ProductSections = ({
    pageData,
}: {
    pageData: any
    gridOption: string
}) => {


    const sections = pageData.documentationSectionsCFM.documentationSections
    let CustomTag: any = ''
    let body: any = ''

    let pageContent: any[] = []

    for ( const section of sections ) {

        CustomTag = section.headingLevel ? `${section.headingLevel.toLowerCase()}` : 'h2'

        let fragment = ''

        if(section.hasOwnProperty('bodyFormat')){
            fragment = section.bodyFormat
        }
    
        switch (fragment) {

            case 'markdown':

                body = <ReactMarkDown content={section.markdown} />                

                break

            case 'solutionsBlockWrapper':

                body = <BlockArrayWrapper 
                    title={section.solutionsBlockWrapper.title}
                    shortDescription={section.solutionsBlockWrapper.shortDescription}
                    textBlockWithImageParts={section.solutionsBlockWrapper.textBlockWithImageParts}
                    gridOption='TenCol'
                    levelDownHeads={section.solutionsBlockWrapper.gridOptions}
                    ctaLink={section.solutionsBlockWrapper.ctaLink}
                    ctaText={section.solutionsBlockWrapper.ctaText}
                    linkAsButton={section.solutionsBlockWrapper.linkAsButton}
                />

                break

            case 'genericSection':

                body = <GenericSection 
                    heading={section.genericSection.heading}
                    bodyFormat={section.genericSection.bodyFormat}
                    body={section.genericSection.body}
                    displayTOC={section.genericSection.displayTOC}
                    gridOption='TenCol'
                />

                break

            default:

                body = <></>
        }

        let renderedSection = (
            <>
                {section.heading && (
                    <CustomTag>{section.heading}</CustomTag>
                )}
                {body && (
                    <div className={styles.documentPageMDContent}>
                        {body}
                    </div>
                )}
            </>
        )

        pageContent.push(renderedSection)
    }

    return (
        <>
            {pageContent}
        </>
    )
}

export default ProductSections