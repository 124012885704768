import '@usb-shield/react-modal/dist/library/styles/index.css'

import React from 'react'
import { useRouter } from 'next/router'
import { useSession } from 'next-auth/react'
import USBLink from '@usb-shield/react-link'
import { downloadDocument } from '@/modules/products/ProductOverviewPage/services/downloadDocument.service'
import styles from '@/modules/products/ProductDocumentPage/DisplayViews/displayViews.module.scss'
import { useCookies } from 'react-cookie'
import { NavItemProps } from '@/modules/products/ProductDocumentPage/components/SideNavMenu/SideNavMenu.model'
import { USBIconExternalLink } from '@usb-shield/react-icons'

const DocumentLinkProcessor = ({
  item,
  hasSubNav,
  isShown,
}: {
  item: NavItemProps<string>
  hasSubNav: boolean | undefined
  isShown: string | undefined
}) => {
  const router = useRouter()
  const { status } = useSession()
  const [cookies] = useCookies(['locale'])
  const title = item.title

  const getDocumentationLinks = (value: any) => {
    const route = router.asPath.split('#')
    let isElavonApiNewTab = false
    if (
      value.metaData.fileType === 'renderAsApiSpec' &&
      process.env.CONTENT_BRAND === 'elavon'
    ) {
      isElavonApiNewTab = true
    }

    const menuLevel = item.id.split('-').length
    const ariaProps: { [index: string]: any } = {}

    ariaProps['label'] = hasSubNav
      ? 'Expandable control for ' + item.title + '. Menu Level ' + menuLevel
      : item.title + '. Menu Level ' + menuLevel

    if (hasSubNav) {
      ariaProps['expanded'] = isShown?.startsWith(item.id)
    }

    if (value.metaData.fileType === 'download') {
      return (
        <USBLink
          linkType="link"
          handleClick={async () => {
            const documentPath = value.path.replace('/', '')
            await downloadDocument(documentPath, value.title, cookies.locale)
          }}
        >
          {title}
        </USBLink>
      )
    }

    return (
      <USBLink
        linkType="link"
        href={value.path}
        target={isElavonApiNewTab ? '_blank' : '_self'}
        addClasses={
          isShown === item.id
            ? `${styles.productDocument} ${styles.selectedTocTitle} ${styles.centerLinkIcon}`
            : `${styles.productDocument} ${styles.centerLinkIcon}`
        }
        ariaProps={ariaProps}
        role="button"
      >
        {title}

        {isElavonApiNewTab && (
          <USBIconExternalLink colorVariant="grey80" size={20} />
        )}
      </USBLink>
    )
  }

  if (status !== 'loading') {
    return <>{getDocumentationLinks(item)}</>
  }

  return <></>
}

export default DocumentLinkProcessor
