import React, { useEffect, useState } from 'react'
import { NavItemProps } from '../SideNavMenu.model'
import DocumentLinkProcessor from '@/modules/products/ProductDocumentPage/components/SideNavMenu/DocumentLinkProcessor'
import styles from '@/modules/products/ProductDocumentPage/components/SideNavMenu/sideNavMenu.module.scss'
import {
  USBIconChevronDown,
  USBIconChevronRight,
} from '@usb-shield/react-icons'
import ChallengeWidgetModal from '@/modules/challenge-widget/ChallengeWidget'
import { getLastStepupDuration } from '@/utils/auth-widget/auth-widget'

const NavItem = ({
  item,
  onItemClick,
  isShown,
  liCssClass,
  divCssClass,
  authWidgetConfigData = null,
}: {
  item: NavItemProps<string>
  onItemClick: any
  isShown: string | undefined
  liCssClass?: string
  divCssClass?: string
  authWidgetConfigData: any
}) => {
  const hasSubNav = item.subNav && item.subNav.length > 0
  const subNav = item.subNav

  let icon
  let tabIndexValue
  if (hasSubNav) {
    tabIndexValue = 0
    icon = isShown?.startsWith(item.id) ? (
      <USBIconChevronDown />
    ) : (
      <USBIconChevronRight />
    )
  }

  // MFA Challenge Widget
  const featureMFA: boolean = process.env.FEATURE_MFA !== 'false'
  const [challengeModalIsOpen, setChallengeModalIsOpen] = useState(false)
  const [activityName, setActivityName] = useState<string>('')
  const [callBackUrl, setCallBackUrl] = useState('')
  useEffect(() => {}, [challengeModalIsOpen])

  const showChallengeWidget = (url: string, activityName: string) => {
    setActivityName(activityName)
    setCallBackUrl(url)
    setChallengeModalIsOpen(true)
  }
  // End MFA Challenge Widget

  return (
    <>
      {/* render Challenge Widget Modal */}

      {challengeModalIsOpen && (
        <ChallengeWidgetModal
          callBackUrl={callBackUrl}
          challengeModalIsOpen={challengeModalIsOpen}
          setChallengeModalIsOpen={setChallengeModalIsOpen}
          authWidgetConfigData={authWidgetConfigData}
          activityName={activityName}
        ></ChallengeWidgetModal>
      )}

      <li className={liCssClass}>
        <div
          onClick={(e: any) => {
            if (featureMFA && item.id == 'usage' && authWidgetConfigData && getLastStepupDuration(authWidgetConfigData.MFA_WIDGET_CHALLENGE_INTERVAL_SECONDS)) {
              e.preventDefault()
              showChallengeWidget(item.path, 'Usage')
            }
          }}
        >
          <div
            onClick={() => onItemClick(item)}
            className={`${divCssClass} ${
              isShown === item.id ? `${styles.selected}` : ''
            }`}
            tabIndex={tabIndexValue}
            onKeyUp={(event) => {
              if (event.key === 'Enter') {
                onItemClick(item)
              }
            }}
          >
            <span id={item.id} className={styles.linkWrapper}>
              <DocumentLinkProcessor
                item={item}
                hasSubNav={hasSubNav}
                isShown={isShown}
              />
            </span>
            {icon}
          </div>
        </div>
        {hasSubNav && (
          <ul
            className={`${styles.sideNavigationPanelSelectInner} ${
              isShown?.startsWith(item.id) ? styles.expanded : ''
            }`}
          >
            {isShown?.startsWith(item.id) &&
              subNav?.map((item: NavItemProps<string>) => {
                const menuLevel = item.id.split('-').length

                return (
                  <NavItem
                    key={`${item.path}-${item.title}`}
                    item={item}
                    onItemClick={onItemClick}
                    isShown={isShown}
                    liCssClass={styles['outerLi-' + menuLevel]}
                    divCssClass={styles.outerDiv}
                    authWidgetConfigData={authWidgetConfigData}
                  />
                )
              })}
          </ul>
        )}
      </li>
    </>
  )
}

export default NavItem
